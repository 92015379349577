<template>
  <ValidationObserver ref="wizardPage2" id="wizardPage2" name="wizardPage2">
    <div>
      <div v-if="appData">
        <instructions :instructions-text="instructions" />

        <div class="form-group row mb-2 mt-2">
          <label class="col-2 col-form-label">Declaration Contact</label>
          <div class="col-4 font-weight-bold">
            <ValidationProvider
              rules="required"
              name="appData.transaction.uiDeclarationContact"
              v-slot="{ errors }"
            >
              <b-form-select
                v-model="appData.transaction.uiDeclarationContact"
                :disabled="isReadOnly || appData.statusFlags.declarationSent"
                @change="saveDeclarationContact"
              >
                <b-form-select-option
                  v-for="contact in getStrataCompanyContacts()"
                  :key="contact.id"
                  :value="contact"
                >
                  {{ contact.Name }} - {{ contact.Email }}
                </b-form-select-option>
              </b-form-select>
              <span class="text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <Document
          :document-name="'Declaration Form'"
          :document-data="appData.documents.declarationForm"
          :mailto-link="declarationMailToLink"
          :on-create-document="createDeclarationForm"
          :processing-indicator="creatingDeclarationForm"
          :is-read-only="isReadOnly || appData.statusFlags.declarationSent"
        >
        </Document>

        <!-- Hidden input to validate if the user created the declaration form -->
        <ValidationProvider
          rules="required-action"
          name="appData.documents.declarationForm.id"
          v-slot="{ errors }"
        >
          <input type="hidden" v-model="appData.documents.declarationForm.id" />
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>

        <div v-if="appData.documents.declarationForm.view_url">
          <ValidationProvider
            :rules="{ 'required-action': { allowFalse: false } }"
            name="appData.statusFlags.declarationSent"
            v-slot="{ errors }"
          >
            <div class="row mt-10">
              <span
                class="switch switch-sm switch-outline switch-icon switch-primary ml-4"
              >
                <label>
                  <input
                    type="checkbox"
                    :disabled="isReadOnly"
                    v-model="appData.statusFlags.declarationSent"
                    @change="
                      $emit('updateServerTransactionStatus', 'declarationSent')
                    "
                  />
                  <span></span>
                </label>
              </span>
              <label class="col-form-label font-size-h5 ml-2">
                I confirm that I have sent the Declaration Form to the Strata
                contact.
              </label>
            </div>
            <span class="text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss"></style>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import Instructions from "../../../common/Instructions";
import Document from "../../../common/Document";

export default {
  name: "DeclarationPage",

  components: {
    Instructions,
    Document,
  },

  props: {
    appData: Object,
    refData: Object,
    isReadOnly: Boolean,
  },

  computed: {
    instructions: function () {
      return "Please create the Declaration Form and then send it to the Declaration Contact. You can change the Declaration Contact if you wish.";
    },

    declarationMailToLink: function () {
      let mailTo = null;

      let decForm = _.get(this.appData, "documents.declarationForm");
      let decContact = _.get(this.appData, "transaction.uiDeclarationContact");
      if (decForm && decForm.view_url && decContact && decContact.id) {
        let email = decContact.Email;
        let name = decContact.K_FirstName || decContact.Name;
        let declarationLink = decForm.view_url;
        let renewalDate = _.get(this.appData, "transaction.K_FromDate");
        if (renewalDate) {
          renewalDate = moment(renewalDate, "YYYY-MM-DD").format("DD/MM/YYYY");
        }

        // TODO Confirm
        let subject = `CIB Declaration Request`;
        let address = _.get(this.appData, "asset.K_Address") || "";
        if (address) {
          subject = `${subject} - ${address}`;
        }
        let planNumber = _.get(this.appData, "asset.K_PlanNumber") || "";
        if (address) {
          subject = `${subject} - ${planNumber}`;
        }
        subject = encodeURIComponent(subject);

        // Add email body
        let emailBody =
          `Dear ${name},%0D%0A %0D%0A` +
          `The insurance policy for ${address} (${planNumber}) is due for renewal on ${renewalDate}. %0D%0A %0D%0A` +
          `In preparation for the renewal, could you please complete the Pre-Renewal Declaration form at your earliest convenience. ` +
          `To complete the form, just click the link below. %0D%0A %0D%0A` +
          `${declarationLink} %0D%0A %0D%0A` +
          `If you have any queries relating to the renewal or the completion of the form, please do not hesitate to contact us.%0D%0A %0D%0A`;

        mailTo = `mailto:${email}?subject=${subject}&body=${emailBody}`;
      }

      return mailTo;
    },
  },

  data() {
    return {
      creatingDeclarationForm: false,
    };
  },

  methods: {
    getStrataCompanyContacts: function () {
      let contacts = [];
      if (
        this.appData &&
        this.appData.strataCompany &&
        this.refData &&
        Array.isArray(this.refData.allStrataCompanies)
      ) {
        let selectedSc = _.find(this.refData.allStrataCompanies, {
          id: this.appData.strataCompany.id,
        });
        if (selectedSc) {
          contacts = selectedSc.contacts;
        }
      }
      return contacts;
    },

    createDeclarationForm: function () {
      this.creatingDeclarationForm = true;
      this.$newBusinessDataService
        .createDeclarationForm()
        .then(({ data }) => {
          if (data.result) {
            let decFormInfo = data.result;
            this.appData.transaction.K_DeclarationFormStatus =
              decFormInfo.K_DeclarationFormStatus;
            this.appData.transaction.K_DeclarationFormLink =
              decFormInfo.K_DeclarationFormLink;
            this.appData.documents.declarationForm = {
              id: decFormInfo.id,
              view_url: decFormInfo.K_DeclarationFormLink,
            };

            this.$emit("updateWizardAppDataString");
          }
          this.creatingDeclarationForm = false;
        })
        .catch((error) => {
          this.creatingDeclarationForm = false;
          this.$emit("handleError", error);
        });
    },

    saveDeclarationContact: function () {
      let decContact = this.appData.transaction.uiDeclarationContact;
      let txnData = {
        K_DeclarationContact: decContact.Name,
        Email: decContact.Email,
      };

      this.$newBusinessDataService
        .updateTransactionData(txnData)
        .then((/*{ data }*/) => {
          this.$emit("updateWizardAppDataString");
        })
        .catch((error) => {
          this.$emit("handleError", error);
        });
    },
  },
};
</script>
