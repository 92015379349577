<template>
  <ValidationObserver ref="wizardPage3" id="wizardPage3" name="wizardPage3">
    <div>
      <pre>{{ JSON.stringify(appData.statusFlags, null, 2) }}</pre> 
      <div v-if="appData">
        <instructions :instructions-text="instructions" />

        <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
          <h4 class="font-weight-bold text-dark">
            <i class="fas fa-file-alt fa-md text-primary"></i>
            <span class="ml-2" style="vertical-align: center">Building and Insurance Details</span>
          </h4>

          <div class="text-center" v-if="appData.declarationRequired === 'yes'">
            <label class="col-form-label font-weight-bold font-size-lg" v-html="declarationStatus"></label>
          </div>

          <div v-if="appData.declarationRequired === 'yes' && !declarationFinalised
            ">
            <div class="text-center">
              <button class="btn btn-outline-danger" @click.prevent="openOverrideConfirmationDialog">
                Broker Override
              </button>
            </div>
            <div class="text-center">
              <!-- Hidden input to validate if the declaration has been finalised -->
              <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }" name="declarationFinalised"
                v-slot="{ errors }">
                <input type="hidden" v-model="declarationFinalised" />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <br />
          </div>

          <div>
            <h6 class="font-weight-bold text-dark mt-6">
              <span class="ml-2" style="vertical-align: center">
                Endorsements / Conditions
              </span>
              <textarea class="form-control mt-3 min-rows" v-model="appData.K_EndorsementsConditions" :disabled="isReadOnly" style="width: 100%;" placeholder="If applicable" maxlength="32000"></textarea>
              <small class="form-text text-muted">Max 32,000 characters</small>
            </h6>
          </div>

          <div v-if="appData.declarationRequired === 'no' || declarationFinalised">
            <div v-if="appData.declarationRequired === 'yes' && !declarationOverridden
              ">
              <div class="text-center">
                <a :href="declarationFormLink" class="btn btn-primary" target="_blank">
                  <i class="fa-regular fa-clipboard fa-md mr-2"></i>
                  Review Declaration Form
                </a>
              </div>
              <br />
            </div>
            
            <declaration-form :formData="appData.declarationData.sumInsuredData"
              :configuration="sumsDataTableConfiguration" :is-read-only="isReadOnly" @delete-item="deleteItem" />

            <declaration-form :formData="appData.declarationData.excessData" :configuration="excessDataTableConfiguration"
              :is-read-only="isReadOnly" @delete-item="deleteItem" />

            <building-properties :form-data="appData.declarationData.assetPropertiesData"
              :configuration="buildingPropertiesConfiguration" :is-read-only="isReadOnly" @delete-item="deleteItem" />
          </div>
        </b-card>
      </div>

      <div v-if="declarationFinalised">
        <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
          name="appData.statusFlags.declarationDetailsConfirmed" v-slot="{ errors }">
          <div class="row mt-10">
            <span class="switch switch-sm switch-outline switch-icon switch-primary ml-4">
              <label>
                <input type="checkbox" :disabled="isReadOnly" v-model="appData.statusFlags.declarationDetailsConfirmed"
                  @change="
                    $emit(
                      'updateServerTransactionStatus',
                      'declarationDetailsConfirmed'
                    )
                    " />
                <span></span>
              </label>
            </span>
            <label class="col-form-label font-size-h5 ml-2 col-10">
              I confirm that I have reviewed the declaration details.
            </label>
          </div>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <b-modal v-if="!isReadOnly" ref="overrideConfirmationDialog" title="Confirm Broker Override" size="md"
        no-close-on-esc no-close-on-backdrop hide-header-close @ok="overrideDeclaration" @cancel="clearOverrideReason">
        Please enter the reason for overriding the Declaration Form.<span class="text-danger">*</span>
        <ValidationProvider rules="required|max:2000" name="appData.brokerOverrideReason" v-slot="{ errors }">
          <div class="form-group mt-5">
            <b-textarea class="form-control min-rows" v-model="overrideReason"></b-textarea>
            <span class="text-danger">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </b-modal>
    </div>
  </ValidationObserver>
</template>

<style>
.form-control.min-rows {
  min-height: calc(1.5em * 3 + 2px); 
}
</style>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import Instructions from "../../../common/Instructions";
import DeclarationForm from "../../../common/DeclarationForm.vue";
import BuildingProperties from "../../../common/BuildingProperties.vue";

export default {
  name: "RenewalPage",

  components: {
    Instructions,
    DeclarationForm,
    BuildingProperties,
  },

  props: {
    appData: Object,
    isReadOnly: Boolean,
  },

  computed: {
    instructions: function () {
      return "Please review and confirm the sums insured and excess details below.";
    },

    declarationFinalised: function () {
      let decStatus = _.get(
        this.appData,
        "transaction.K_DeclarationFormStatus"
      );
      return decStatus === "Submitted" || decStatus === "Overridden";
    },

    declarationOverridden: function () {
      let decStatus = _.get(
        this.appData,
        "transaction.K_DeclarationFormStatus"
      );
      return decStatus === "Overridden";
    },

    declarationFormLink: function () {
      let decFormLink = _.get(
        this.appData,
        "transaction.K_DeclarationFormLink"
      );
      if (!decFormLink) {
        decFormLink = _.get(this.appData, "documents.declarationForm.view_url");
      }
      return decFormLink;
    },

    declarationStatus: function () {
      let decReceivedTimestamp = _.get(
        this.appData,
        "transaction.K_DeclarationFormSubmissionOverrideTime"
      );
      if (decReceivedTimestamp) {
        decReceivedTimestamp = moment
          .tz(decReceivedTimestamp, "Australia/Sydney")
          .format("DD/MM/YYYY hh:mm:ss A");
      }

      let decStatus = `Awaiting Declaration Form from the Strata contact.`;
      if (decReceivedTimestamp) {
        if (this.declarationOverridden) {
          let overridingBroker =
            _.get(this.appData, "transaction.K_OverridingBroker") || "Broker";
          decStatus = `<span class="text-danger">Declaration Form was overridden by ${overridingBroker} on ${decReceivedTimestamp}.</span>`;
          let reason = _.get(this.appData, "transaction.K_OverrideReason");
          if (reason) {
            decStatus = `${decStatus}<br/>Reason: ${reason}`;
          }
        } else {
          let decFormSubmittedBy =
            _.get(this.appData, "transaction.K_DeclarationFormSubmittedBy") ||
            "Strata Contact";
          decStatus = `<span class="text-primary">Declaration Form was submitted by ${decFormSubmittedBy} on ${decReceivedTimestamp}.</span>`;
        }
      }
      return decStatus;
    },

    sumsDataTableConfiguration: function () {
      return {
        title: "Sums Insured",
        renewalTitle: "Value",
        showNewValue: true,
        allowDeletion: true,
        allowAddition: true,
        addButtonTitle: "",
      };
    },

    excessDataTableConfiguration: function () {
      return {
        title: "Excesses",
        renewalTitle: "Value",
        showNewValue: true,
        allowDeletion: true,
        allowAddition: true,
        addButtonTitle: "",
      };
    },

    buildingPropertiesConfiguration: function () {
      return {
        title: "Building Properties",
        newValueTitle: "Value",
        showNewValue: true,
        allowDeletion: true,
        allowAddition: true,
        addButtonTitle: "",
      };
    },
  },

  data() {
    return {
      overrideReason: null,
    };
  },

  methods: {
    openOverrideConfirmationDialog: function () {
      this.$refs["overrideConfirmationDialog"].show();
    },

    overrideDeclaration: function (bvModalEvt) {
      if (!this.overrideReason) {
        bvModalEvt.preventDefault();
        return;
      }
      this.$emit("overrideDeclaration", this.overrideReason);
    },

    clearOverrideReason: function () {
      this.overrideReason = null;
    },

    deleteItem: function (item, formData) {
      let index = formData.findIndex(dataItem => dataItem === item);
      if (index !== -1) {
        formData.splice(index, 1);
      }
    },
  },
};
</script>
